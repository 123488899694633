<template>
  <div class="outDiv">
    <div :class="['table-main', tableData.length ? 'animated fadeInUp' : '']">
      <div class="my-table">
        <MyTable
          :tableData="tableData"
          :configTable="configTable"
          @handleEdit="handleEdit"
          @handleStart="handleStart"
          @handleComplete="handleComplete"
          @handleSee="handleSee"
          @handleDelete="handleDelete"
        ></MyTable>
        <el-pagination
          v-if="tableData.length"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <MyPhone
        :isBoxShowNum="isBoxShowNum"
        @handlePreview="handlePreview"
        @noDel="noDel"
        @delSn="delSn"
        @handleNoEditTime="handleNoEditTime"
        @handleOkEdit="handleOkEdit"
        :id="id"
        :sn="sn"
        :location="location"
        :position="position"
        :device_id="device_id"
        :plot_title="plot_title"
        :plot_id="plot_id"
      ></MyPhone>
    </div>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import { getBatchListApi, getPlotInfoApi } from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    MyTable: () => import("@/components/MyTable.vue"),
    MyPhone: () => import("@/components/MyPhone.vue"),
  },
  data() {
    return {
      total: 20,
      current_page: 1,
      product_id: undefined,
      configTable: [
        {
          prop: "title",
          label: "批次名称",
          width: "154",
        },
        {
          prop: "SN",
          label: "批次号",
          width: "262",
        },
        {
          prop: "plot_title",
          label: "地块",
          width: "144",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "250",
        },
        {
          prop: "",
          label: "操作",
          width: "",
          editType: true,
          completeType3: true,
          startType3: true,
          seeType: true,
          delType: true,
        },
      ],
      tableData: [],
      isBoxShowNum: 0,
      id: undefined,
      plot_title: undefined,
      plot_id: undefined,
      sn: "",
      device_id: "",
      location: "",
      position: "",
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getBatchList();
  },

  methods: {
    handleNoEditTime() {
      this.isBoxShowNum = 0;
    },
    delSn() {
      this.getBatchList();
      this.isBoxShowNum = 0;
    },
    handleOkEdit() {
      this.getBatchList();
      this.isBoxShowNum = 0;
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getBatchList();
    },
    noDel() {
      this.isBoxShowNum = 0;
    },
    async getBatchList() {
      showFullScreenLoading();
      this.product_id = this.$route.query.id;
      const { code, results } = await getBatchListApi({
        manor_id: this.farmID,
        pn: this.current_page,
        product_id: this.product_id,
      });
      if (code === 0) {
        this.total = results.count;
        this.current_page = results.pn;
        this.tableData = results.data;
      }
      tryHideFullScreenLoading();
    },
    handleStart({ id }) {
      this.id = id;
      this.isBoxShowNum = 5;
    },
    handleComplete({ id }) {
      this.id = id;
      this.isBoxShowNum = 6;
    },
    handleEdit({ id, plot_title, plot_id }) {
      this.id = id;
      this.plot_title = plot_title;
      this.plot_id = plot_id;
      this.isBoxShowNum = 1;
    },
    handlePreview() {
      this.isBoxShowNum = 2;
    },
    myFun(result) {
      this.location = encodeURIComponent(result.name);
      this.position = `${result.center.lng},${result.center.lat}`;
    },
    handleSee({ SN, plot_id }) {
      let myCity = new window.BMapGL.LocalCity();
      myCity.get(this.myFun);
      this.sn = SN;
      this.getPlotInfoApi(plot_id);
    },

    handleDelete({ id, SN }) {
      this.sn = SN;
      this.id = id;
      this.isBoxShowNum = 4;
    },
    async getPlotInfoApi(plot_id) {
      const { code, results } = await getPlotInfoApi({
        manor_id: this.farmID,
        plot_id,
      });
      if (code != 0) return;
      this.device_id = results.device_id;
      this.isBoxShowNum = 3;
    },
  },
};
</script>

<style lang="less" scoped>
.outDiv {
  padding: 0 48px;
  .table-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #0e2138;
    padding: 37px 49px;

    .my-table {
      min-height: 690px;
      min-width: 1250px;
      .time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media (min-width: 768px) {
  .outDiv {
    padding: 0;
    .table-main {
      .my-table {
        width: calc(100% - 351px);
      }
    }
  }
}
</style>
